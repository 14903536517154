<template>
  <div>
    <b-alert v-if="getErrorMsg" show variant="danger">{{
      getErrorMsg
    }}</b-alert>
    <div v-if="getLoadingItemState">
      <b-spinner label="Loading..."></b-spinner>
    </div>

    <b-card v-else>
      <h2>{{ $t("items.header") }}</h2>
      <!-- Order details in card view -->
      <section>
        <b-table
          v-if="showTable"
          hover
          striped
          sticky-header
          outlined
          :items="allItems"
          :fields="fields"
        >
          <template #cell( )="data">
            <router-link
              class="btn btn-default btn-sm mr-2"
              :to="'/admin/items/edit/' + data.item.id"
              >{{ $t("items.edit") }}</router-link
            >
          </template>
        </b-table>

        <b-card-group v-else columns>
          <b-card
            title=""
            tag="article"
            style="max-width: 25rem; min-width:1rem;"
            class="m-3"
            v-for="item in allItems"
            :key="item.id"
          >
            <h6 slot="header" class="mb-0">Item nr {{ item.itemNo }}</h6>
            <b-card-text>
              <h4>
                {{ item.name }}
              </h4>
              <p>Description: {{ item.description }}</p>
            </b-card-text>
            <!-- <b-button @click="showModal" class="" variant="primary">Edit</b-button> -->
            <router-link class="btn btn-light" :to="'edit/' + item.id">{{
              $t("items.edit")
            }}</router-link>
            <!-- <b-button variant="danger" @click="deleteItem(item.id)" class="m-3">Delete</b-button> -->
            <div slot="footer">
              <small class="text-muted"
                >Last edited: {{ convertDate(item.modified) }}</small
              >
            </div>
          </b-card>
        </b-card-group>
      </section>
    </b-card>

    <!-- Modal start -->
    <b-modal ref="my-modal" hide-footer title="Add Item">
      <form>
        <b-container fluid>
          <b-row class="my-1">
            <b-col sm="3">
              <label for="input-none">Item</label>
            </b-col>
            <b-col sm="9">
              <b-form-input list="my-list-id" requried></b-form-input>
              <datalist id="my-list-id">
                <!-- <option>Manual Option</option> -->
                <option v-for="i in allItems" :key="i.id">{{ i.name }}</option>
              </datalist>
            </b-col>
          </b-row>

          <b-row class="my-1">
            <b-col sm="3">
              <label for="freetext">FreeText:</label>
            </b-col>
            <b-col sm="9">
              <b-form-input id="freetext" type="text"></b-form-input>
            </b-col>
          </b-row>

          <b-row>
            <b-button variant="primary" @click="hideModal" class="m-3"
              >Add</b-button
            >
            <b-button variant="danger" @click="hideModal" class="m-3"
              >Cancel</b-button
            >
          </b-row>
        </b-container>
      </form>
    </b-modal>

    <DeleteItemModal ref="deleteModal"></DeleteItemModal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DeleteItemModal from "@/components/DeleteItemModal.vue";

export default {
  name: "items",
  components: {
    DeleteItemModal
  },
  data() {
    return {
      showTable: true,
      fields: [
        {
          key: "itemNo",
          label: "Item no"
        },
        {
          key: "name",
          label: "Item name"
        },
        {
          key: "description",
          label: "Description"
        },
        " "
      ]
    };
  },
  methods: {
    ...mapActions(["getItems", "deleteItem"]),
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    showDeleteModal() {
      this.$refs["deleteModal"].show();
    },
    convertDate(datetime) {
      // return  new Date(date).toLocaleDateString;
      let date = new Date(datetime)
        .toJSON()
        .slice(0, 10)
        .replace(/-/g, "/");
      return date;
    }
  },
  computed: mapGetters(["allItems", "getLoadingItemState", "getErrorMsg"]),
  created() {
    this.getItems();
  }
};
</script>

<style>
.cards {
  min-height: 155px;
}
</style>
