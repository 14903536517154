<template>
  <div>
    <b-modal hide-footer title="Add Item">
      <b-container fluid>
        <p>Are you sure?</p>
        <b-button variant="primary">OK</b-button>
        <b-button variant="danger">Cancel</b-button>
      </b-container>
    </b-modal>

    <b-toast
      id="example-toast"
      title="BootstrapVue"
      static
      show="getDeleteItem.showModal"
    >
      {{ getDeleteItem.msg }}
    </b-toast>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DeleteItemModal",
  computed: mapGetters(["getDeleteItem"])
  // watch: {
  //     'deleteItem': function () {

  //     }
  // }
};
</script>

<style></style>
